import React, { forwardRef, useRef, useImperativeHandle } from "react"
import firebase from "firebase"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faTrashRestore } from "@fortawesome/free-solid-svg-icons"
import AlbumContext from "../../context/albumContext"
import { ItemTypes } from "./itemTypes"
import { DragSource, DropTarget } from "react-dnd"

import "./trackItem.scss"
import TrackInfoPopupButton from "./trackInfoPopupButton"

const TrackItem = forwardRef(function TrackItem({ track, isDragging, connectDragSource, connectDropTarget }, ref) {
  const elementRef = useRef(null)

  connectDragSource(elementRef)
  connectDropTarget(elementRef)

  const opacity = isDragging ? 0 : 1
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }))

  const context = React.useContext(AlbumContext)

  const toDelete = context.edits.tracksToDelete !== undefined && context.edits.tracksToDelete.find((t) => t.uuid === track.uuid)

  const toggleDelete = async (track) => {
    if(!toDelete) {
      context.setEdits({ ...context.edits, tracksToDelete: [...context.edits.tracksToDelete, track] })
    } else {
      context.setEdits({ ...context.edits, tracksToDelete: context.edits.tracksToDelete.filter(t => t.uuid !== track.uuid) })
    }
  }

  return (
    <div ref={elementRef} style={{ opacity }}>
      <div
        className="box track"
        key={track.name}
        style={{
          backgroundColor: toDelete ? "darkred" : track.meta?.backgroundColor || "inherit",
          color: toDelete ? "white" : track.meta?.textColor || "inherit",
        }}>
        <div className="media">
          <div className="media-content">
            <div className="content">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <p>
                      <span className="track-name">{toDelete ? `REMOVING - ${track.name}` : track.name}</span>
                    </p>
                  </div>
                </div>
                <div className="level-right actions">
                  <div className="field" style={{ marginBottom: 0 }}>
                    <div className="label" style={{ fontSize: "0.75rem" }}>
                      Display?
                    </div>
                    <input
                      type="checkbox"
                      id={`track-item-${track.order}-is-enabled`}
                      name={`track-item-${track.order}-is-enabled`}
                      className="switch is-link"
                      defaultChecked={track.disabled !== true}
                      onChange={async (e) => {
                        await firebase
                          .firestore()
                          .collection("albums")
                          .doc(context.id)
                          .update({
                            [`tracks.${btoa(encodeURIComponent(track.name))}`]: {
                              ...track,
                              disabled: !e.target.checked,
                            },
                          })
                      }}
                    />
                    <label htmlFor={`track-item-${track.order}-is-enabled`}>&nbsp;</label>
                  </div>
                  <TrackInfoPopupButton track={track} />
                  <FontAwesomeIcon icon={ toDelete ? faTrashRestore : faTrash} className="has-text-danger icon" onClick={() => toggleDelete(track)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

/**
 * See: https://codesandbox.io/s/github/react-dnd/react-dnd/tree/gh-pages/examples_decorators_js/04-sortable/simple?from-embed=&file=/src/Container.jsx
 */
export default DropTarget(
  ItemTypes.TRACK,
  {
    hover(props, monitor, component) {
      if (!component) {
        return null
      }
      // node = HTML Div element from imperative API
      const node = component.getNode()
      if (!node) {
        return null
      }
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = node.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      props.moveTrack(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    ItemTypes.TRACK,
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(TrackItem)
)
