import React from "react"
import ImportContext from "../../context/ImportContext"
import Loader from "../loader"

export default function Progress(props) {
  const context = React.useContext(ImportContext)

  return (
    <div className="Progress">
      <section className="section">
        <div className="container">
          <div className="panel">
            <div className="panel-block">
              <div className="container">
                <div className="my-3">
                  <Loader description={context.status}></Loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
