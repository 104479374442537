import React from "react"

const GroupEditorForm = function ({ doc }) {
  return (
    <div className="EditorForm GroupEditorForm">
      <h2 className="title">Code Generator</h2>
      <form>
        <div className="field">
          <label className="label">Title</label>
          <div className="control">
            <input
              className="input"
              name="title"
              type="text"
              placeholder="Generated Code"
              defaultValue={doc ? doc.data().title : null}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Description</label>
          <div className="control">
            <input
              className="input"
              name="description"
              type="text"
              placeholder="An autogenerated code..."
              defaultValue={doc ? doc.data().description : null}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Downloads Allowed</label>
          <input
            type="number"
            name="downloadsAllowed"
            className="input"
            defaultValue={doc ? doc.data().downloadsAllowed : 1}
            min={1}
          />
        </div>
      </form>
    </div>
  )
}

export default GroupEditorForm
