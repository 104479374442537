import firebase from "firebase"

const GenerateReportButton = ({ id }) => {
  const generateReport = async () => {
    const token = await firebase.auth().currentUser.getIdToken()
    window.location = `https://us-central1-the-vigil-project.cloudfunctions.net/generateReport?token=${token}&albumId=${id}`
  }

  return (
    <button className="button is-warning" onClick={generateReport} style={{ marginRight: 5 }}>
      Generate Report
    </button>
  )
}

export default GenerateReportButton
