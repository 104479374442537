import React from "react"
import AlbumContext from "../../context/albumContext"
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const SaveButton = () => {
    const ReactSwal = withReactContent(Swal)
    const context = React.useContext(AlbumContext)

    const handleDeletingPopup = () => {
        ReactSwal.fire({
            title: "Deleting Files or Tracks",
            html: (
                <div>
                    <p>Some files or tracks will be deleted. Are you sure you want to continue?</p>
                </div>
            ),
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                context.save()
            }
        })
    }

    return (
        <button className="button is-link"
                disabled={Object.keys(context.edits).length <= 2 && !context.edits.filesToDelete.length
                    && !context.edits.tracksToDelete.length}
                onClick={context.edits.filesToDelete.length || context.edits.tracksToDelete.length ?
                    handleDeletingPopup :
                    context.save
                }
        >
            Save
        </button>
    )
}

export default SaveButton
