import React from "react"
import AlbumContext from "../../context/albumContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

const FileDropzoneList = () => {
  const context = React.useContext(AlbumContext)

  return context.edits.files
    .sort((a, b) => (a.name >= b.name ? 1 : -1))
    .map((file) => (
      <div className="box file" key={file.name}>
        <div className="media">
          <div className="media-content">
            <div className="content">
              <div className="level">
                <div className="level-left">
                  <div className="level-item">
                    <p>
                      <span className="name">{file.name}</span>
                    </p>
                  </div>
                </div>
                <div className="level-right">
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="has-text-danger icon"
                    onClick={() => {
                      const temp = [...context.edits.files]
                      const index = temp.findIndex((t) => t.name === file.name)
                      temp.splice(index, 1)
                      context.setEdits({ ...context.edits, files: temp })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
}

export default FileDropzoneList
