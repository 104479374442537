import React from "react"
import { withRouter } from "react-router-dom"

import Loader from "../components/loader"
import SearchBar from "../components/searchBar"

import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/storage"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      albums: [],
      pictures: {},
      hasError: false,
      errorDescription: null,
    }
  }

  componentDidMount() {
    this.getItems()
  }

  getItems = async () => {
    try {
      const pictures = {}
      const albums = await firebase.firestore().collection("albums").get()

      for (const album of albums.docs) {
        const pictureUrl = await firebase.storage().ref(album.data().picture).getDownloadURL()
        pictures[album.id] = pictureUrl
      }

      this.setState({ ...this.state, albums: albums.docs, pictures, isLoaded: true })
    } catch (err) {
      this.setState({ ...this.state, hasError: true, errorDescription: err.message })
    }
  }

  generateReport = async () => {
    const token = await firebase.auth().currentUser.getIdToken()
    window.location = `https://us-central1-the-vigil-project.cloudfunctions.net/generateReport?token=${token}`
  }

  render() {
    return (
      <div className="IndexPage">
        <section className="section">
          <div className="container">
            {this.state.hasError && this.state.errorDescription && (
              <div className="message is-danger">
                <div className="message-header">Error</div>
                <div className="message-body">{this.state.errorDescription}</div>
              </div>
            )}
            {!this.state.hasError && (
              <div className="panel is-link">
                <div className="panel-heading is-flex">
                  <p>Albums</p>
                  <SearchBar />
                  <div className="generate-report">
                    <button className="button is-warning" onClick={this.generateReport}>
                      Generate Global Report
                    </button>
                  </div>
                </div>
                {!this.state.isLoaded && (
                  <div className="panel-block">
                    <div className="container">
                      <Loader />
                    </div>
                  </div>
                )}
                {this.state.isLoaded &&
                  this.state.albums.map((album) => (
                    <div
                      className="panel-block album"
                      key={album.id}
                      onClick={() => this.props.history.push(`/album/${album.id}`)}>
                      <div className="container">
                        <div className="box">
                          <div className="media">
                            <div className="media-left">
                              <figure className="image is-64x64">
                                <img src={this.state.pictures[album.id]} alt="album-art" />
                              </figure>
                            </div>
                            <div className="media-content">
                              <div className="content">
                                <h2>{album.data().name}</h2>
                                <p>{album.data().description}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="panel-block">
                  <button
                    className="button is-link is-block is-outlined is-fullwidth"
                    onClick={() => this.props.history.push("/create/album")}>
                    Create New Album
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    )
  }
}

export default withRouter(IndexPage)
