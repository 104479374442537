import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar, faTrash, faTrashRestore } from "@fortawesome/free-solid-svg-icons"
import AlbumContext from "../../context/albumContext"

const FileItem = ({ file, featured }) => {
  const context = React.useContext(AlbumContext)

  const setFeaturedFile = async (file) => {
    if (featured.file.name !== file.name) {
      await context.albumDoc.ref.update({ ["featured.file"]: file })
      context.setAlbum({ ...context.album, featured: { ...context.album.featured, file } })
    }
  }

  const toDelete = context.edits.filesToDelete !== undefined && context.edits.filesToDelete.find((f) => f.uuid === file.uuid)

  const toggleDelete = async (file) => {
    if(!toDelete) {
      context.setEdits({ ...context.edits, filesToDelete: [...context.edits.filesToDelete, file] })
    } else {
      context.setEdits({ ...context.edits, filesToDelete: context.edits.filesToDelete.filter(f => f.uuid !== file.uuid) })
    }
  }

  return (
    <div
        className="box file"
        key={file.name}
        style={{
          backgroundColor: toDelete ? "darkred" : "inherit",
          color: toDelete ? "white" : "inherit",
        }}>
      <div className="media">
        <div className="media-content">
          <div className="content">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <p>
                    <span className="mr-3">
                      {featured.file.name === file.name && <FontAwesomeIcon icon={faStar} className="icon" />}
                      {featured.file.name !== file.name && (
                        <FontAwesomeIcon icon={faStar} className="icon" onClick={setFeaturedFile.bind(this, file)} />
                      )}
                    </span>
                    <span className="track-name">{toDelete ? `REMOVING - ${file.name}` : file.name}</span>`
                  </p>
                </div>
              </div>
              <div className="level-right">
                <FontAwesomeIcon icon={toDelete ? faTrashRestore : faTrash} className="has-text-danger icon" onClick={() => toggleDelete(file)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileItem
