import React from "react"
import ReactTooltip from "react-tooltip"

import Swal from "sweetalert2"
import firebase from "firebase"
import BulmaCollapsible from "@creativebulma/bulma-collapsible"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faFolderMinus, faChevronDown, faPen } from "@fortawesome/free-solid-svg-icons"

import { generateCode, handleCodeUpdateSwalValidate, persistCode } from "../helpers"
import withReactContent from "sweetalert2-react-content"
import CodeGeneratorForm from "./codeGeneratorForm"
import GroupEditorForm from "./groupEditorForm"

import "./codeList.scss"

const ReactSwal = withReactContent(Swal)

class GroupList extends React.Component {
  constructor(props) {
    super(props)
    this.albumId = props.albumId
    this.registerListener = props.registerListener
    this.deregisterListener = props.deregisterListener
    this.state = {
      elementId: generateCode(12),
      groups: props.groups,
      sort: "title",
      ordering: "desc",
    }
    this.containerRef = React.createRef()
    this.collapseInstances = []
  }

  componentDidMount() {
    this.collapseInstances = BulmaCollapsible.attach(".is-collapsible", {
      container: this.containerRef.current,
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.groups !== this.state.groups) {
      this.setState({ ...this.state, groups: nextProps.groups })
    }
    return true
  }

  deleteCode = async (group, code) => {
    await firebase.firestore().collection("codes").doc(code).delete()

    const newList = [...group.data().codes]
    const index = newList.indexOf(code)
    newList.splice(index, 1)
    await group.ref.update({ codes: newList })
  }

  deleteGroup = async (generationId) => {
    const query = await firebase.firestore().collection("codes").where("generationId", "==", generationId).get()
    const increment = 500
    for (let batchIndex = 0; batchIndex < query.docs.length; batchIndex += increment) {
      const batch = firebase.firestore().batch()
      const limit = batchIndex + increment < query.docs.length ? batchIndex + increment : query.docs.length

      for (let index = batchIndex; index < limit; index++) {
        batch.delete(query.docs[index].ref)
      }

      await batch.commit()
    }
    await firebase.firestore().collection("groups").doc(generationId).delete()
  }

  onEditClicked = async (code) => {
    const currentDoc = await firebase.firestore().collection("codes").doc(code).get()
    ReactSwal.fire({
      html: <CodeGeneratorForm doc={currentDoc} />,
      showCancelButton: true,
      confirmButtonText: "Update",
      preConfirm: async () => {
        const newData = handleCodeUpdateSwalValidate()
        ReactSwal.showLoading()
        Swal.disableButtons()
        await this.updateCode(currentDoc, newData)
        ReactSwal.hideLoading()
      },
    })
  }

  updateCode = async (currentDoc, newData) => {
    const ref = firebase.firestore().collection("codes").doc(currentDoc.id)
    if (newData.code && currentDoc.id !== newData.code) {
      await ref.delete()
      await persistCode({
        albumId: currentDoc.data().albumId,
        ...newData,
        downloadsUsed: currentDoc.data().downloadsUsed,
      })
    } else {
      await ref.update({ ...newData })
    }
  }

  updateGroup = async (currentDoc) => {
    try {
      const data = handleCodeUpdateSwalValidate(true)
      ReactSwal.showLoading()
      Swal.disableButtons()

      await currentDoc.ref.update({ ...data })
      await this.updateCodes(currentDoc.data().codes, data)

      ReactSwal.hideLoading()
    } catch (err) {
      ReactSwal.showValidationMessage(err)
    }
  }

  updateCodes = async (codesList, { title, description, downloadsAllowed }) => {
    const increment = 500
    for (let batchIndex = 0; batchIndex < codesList.length; batchIndex += increment) {
      const batch = firebase.firestore().batch()
      const limit = batchIndex + increment < codesList.length ? batchIndex + increment : codesList.length

      for (let codeIndex = batchIndex; codeIndex < limit; codeIndex++) {
        const ref = firebase.firestore().collection("codes").doc(codesList[codeIndex])
        batch.update(ref, { title, description, downloadsAllowed })
      }

      await batch.commit()
    }
  }

  renderResult = (group, code) => {
    return (
      <tr key={code}>
        <td className="hover-pointer" onClick={this.onEditClicked.bind(this, code)}>
          {code}
        </td>
        <td>
          <div className="level">
            <FontAwesomeIcon
              data-tip="Delete Code"
              icon={faTrash}
              className="has-text-danger icon"
              onClick={() =>
                Swal.fire({
                  icon: "warning",
                  title: "Are you sure?",
                  text: `This will remove access to code: '${code}'`,
                  confirmButtonText: "Delete",
                  showCancelButton: true,
                  focusCancel: true,
                  preConfirm: async () => {
                    try {
                      Swal.showLoading()
                      await this.deleteCode(group, code)
                    } catch (err) {
                      Swal.showValidationMessage(err)
                    }
                  },
                })
              }
            />
          </div>
        </td>
      </tr>
    )
  }

  renderResults = () => {
    const results = []
    let groups = Array.isArray(this.state.groups) ? this.state.groups : Object.values(this.state.groups)

    if (!groups.length) {
      return (
        <article className="message is-danger">
          <div className="message-header">No Groups found.</div>
        </article>
      )
    }

    groups.sort((a, b) => {
      const propA = a.data()[this.state.sort]
      const propB = b.data()[this.state.sort]

      if (this.state.ordering === "asc") {
        return propA < propB ? 1 : -1
      } else {
        return propA > propB ? 1 : -1
      }
    })

    for (const group of groups) {
      const items = []
      const data = group.data()
      const title = data.title
      const cleaned = title.toLowerCase().replace(/[\s\W]/g, "-")

      var count = 0
      for (const code of data.codes.sort((a, b) => (a > b ? 1 : -1))) {
        if (count > 30) {
          items.push(
            <div className="message">
              <div className="message-body">And many more... Generate report to list all codes.</div>
            </div>
          )
          break
        }
        items.push(this.renderResult(group, code))
        count++
      }

      results.push(
        <div id={cleaned} key={cleaned} className="code-group card">
          <header className="card-header">
            <a href={`#${cleaned}-body`} data-action="collapse" aria-hidden={true}>
              <p className="card-header-title">
                {title} - {data.description}
              </p>
              <div className="stats">
                <span className="item-count">
                  {data.codes.length} {data.codes.length === 1 ? "code" : "codes"}
                </span>
                {", "}
                <span className="createdAt">Created: {data.createdAt.toDate().toLocaleDateString()}</span>
              </div>
              <div className="icons">
                <FontAwesomeIcon
                  data-tip="Edit group information"
                  icon={faPen}
                  className="icon is-linklike"
                  onClick={() =>
                    ReactSwal.fire({
                      html: <GroupEditorForm doc={group} />,
                      confirmButtonText: "Update",
                      showCancelButton: true,
                      focusCancel: true,
                      preConfirm: async () => {
                        try {
                          Swal.showLoading()
                          this.updateGroup(group)
                        } catch (err) {
                          Swal.showValidationMessage(err)
                        }
                      },
                    })
                  }
                />
                <FontAwesomeIcon
                  data-tip="Delete Batch of Codes"
                  icon={faFolderMinus}
                  className="has-text-danger icon"
                  onClick={() =>
                    Swal.fire({
                      icon: "warning",
                      title: "Are you sure?",
                      text: `This will remove all codes generated in this Group.\nDepending on the number of codes, this may take a bit of time.`,
                      confirmButtonText: "Delete",
                      showCancelButton: true,
                      focusCancel: true,
                      preConfirm: async () => {
                        try {
                          Swal.showLoading()
                          await this.deleteGroup(group.id)
                        } catch (err) {
                          Swal.showValidationMessage(err)
                        }
                      },
                    })
                  }
                />
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </a>
          </header>
          <div id={`${cleaned}-body`} className="is-collapsible" data-parent={cleaned}>
            <div className="card-content">
              <table className="table is-hoverable is-fullwidth">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{items}</tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }

    return results
  }

  render() {
    return (
      <div className="CodeList" ref={this.containerRef}>
        <div className="sorting">
          <div className="control">
            Property:{" "}
            <label className="radio">
              <input
                type="radio"
                name={`sort-${this.state.elementId}`}
                value="title"
                checked={this.state.sort === "title"}
                onChange={() => this.setState({ ...this.state, sort: "title" })}
              />{" "}
              Title
            </label>
            <label className="radio">
              <input
                type="radio"
                name={`sort-${this.state.elementId}`}
                value="createdAt"
                checked={this.state.sort === "createdAt"}
                onChange={() => this.setState({ ...this.state, sort: "createdAt" })}
              />{" "}
              CreatedAt
            </label>
            <label className="radio">
              <input
                type="radio"
                name={`ordering-${this.state.elementId}`}
                value="asc"
                checked={this.state.ordering === "asc"}
                onChange={() => this.setState({ ...this.state, ordering: "asc" })}
              />{" "}
              Ascending
            </label>
            <label className="radio">
              <input
                type="radio"
                name={`ordering-${this.state.elementId}`}
                value="desc"
                checked={this.state.ordering === "desc"}
                onChange={() => this.setState({ ...this.state, ordering: "desc" })}
              />{" "}
              Descending
            </label>
          </div>
        </div>
        {this.renderResults()}
        <ReactTooltip />
      </div>
    )
  }
}

export default GroupList
