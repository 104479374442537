import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import firebase from "firebase"
import "firebase/auth"

import Navbar from "./components/navbar"
import IndexPage from "./pages/index"
import LoginPage from "./pages/login"
import CreateAlbumPage from "./pages/createAlbum"
import Helpers from "./pages/helpers"
import ViewAlbumPage from "./pages/viewAlbum"
import Loader from "./components/loader"
import ImportPage from "./pages/import"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

class App extends React.Component {
  constructor(props) {
    super(props)
    this.authListener = null
    this.state = {
      isLoading: true,
      user: null,
    }
  }

  componentDidMount() {
    this.authListener = firebase.auth().onAuthStateChanged(this.handleAuthStateChanged)
  }

  handleAuthStateChanged = (user) => this.setState({ isLoading: false, user })

  render() {
    return (
      <Router>
        <div className="App">
          <DndProvider backend={HTML5Backend}>
            <Navbar />
            {this.state.isLoading && (
              <section className="section">
                <div className="container">
                  <Loader color="white" />
                </div>
              </section>
            )}
            {this.state.user && (
              <Switch>
                <Route path="/login" exact={true} component={LoginPage}></Route>
                <Route path="/album/:id" exact={true} component={ViewAlbumPage} />
                <Route path="/create/album" exact={true} component={CreateAlbumPage} />
                <Route path="/helpers" exact={true} component={Helpers} />
                <Route path="/import" exact={true} component={ImportPage} />
                <Route path="/" component={IndexPage} />
              </Switch>
            )}
            {!this.state.isLoading && !this.state.user && <Route component={LoginPage}></Route>}
          </DndProvider>
        </div>
      </Router>
    )
  }
}

export default App
