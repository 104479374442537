import React from "react"
import Dropzone from "react-dropzone"
import AlbumContext from "../../context/albumContext"

import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

const ReactSwal = withReactContent(Swal)

const FileDropzone = () => {
  const context = React.useContext(AlbumContext)

  const onFileDrop = (files) => context.setEdits({ ...context.edits, files })

  const onDropRejected = (rejections, event) => {
    for (const rejection of rejections) {
      ReactSwal.fire({
        title: "File Drop Exception",
        text: rejection.errors[0].message,
        toast: true,
        icon: "error",
        position: "top-right",
        timer: 5000,
        showCancelButton: false,
        showConfirmButton: false,
      })
    }
  }

  const validate = (file) => {
    for (const [, existingFile] of Object.entries(context.album.files)) {
      if (file.name === existingFile.name) {
        return {
          code: "duplicate-not-permitted",
          message: `Duplicate files are not permitted. '${file.name}' already exists. Please rename and try again.`,
        }
      }
    }
  }

  return (
    <Dropzone onDrop={onFileDrop} validator={validate} onDropRejected={onDropRejected}>
      {({ getRootProps, getInputProps }) => (
        <section className="dropzone">
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>Drag and drop files here, or click to select files.</p>
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default FileDropzone
