import React from "react"
import ReactDOM from "react-dom"
import firebase from "firebase/app"

import "./styles/master.scss"
import App from "./app"

require("@creativebulma/bulma-collapsible")

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAyAW9gVgGGICtd16AZfzJBJs0jQ9-V4fA",
  authDomain: "the-vigil-project.firebaseapp.com",
  databaseURL: "https://the-vigil-project.firebaseio.com",
  projectId: "the-vigil-project",
  storageBucket: "the-vigil-project.appspot.com",
  messagingSenderId: "396517643256",
  appId: "1:396517643256:web:4b37f4fa86a79040b53c0d",
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
