import React from "react"

const CodeGeneratorForm = function ({ doc }) {
  const [isSingleGenerator, setIsSingleGenerator] = React.useState(doc ? !!doc.data().isCustom : true)

  return (
    <div className="EditorForm CodeGeneratorForm">
      <h2 className="title">{doc && doc.id ? `Editing '${doc.id}'` : "Code Generator"}</h2>
      <form>
        <div className="field">
          <label className="label">Title</label>
          <div className="control">
            <input
              className="input"
              name="title"
              type="text"
              placeholder="Generated Code"
              defaultValue={doc ? doc.data().title : null}
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Description</label>
          <div className="control">
            <input
              className="input"
              name="description"
              type="text"
              placeholder="An autogenerated code..."
              defaultValue={doc ? doc.data().description : null}
            />
          </div>
        </div>
        {!doc && (
          <>
            <div className="field">
              <div className="control">
                <label className="radio">
                  <input
                    type="radio"
                    name="is-single"
                    checked={isSingleGenerator}
                    onChange={(e) => setIsSingleGenerator(e.target.checked)}
                    disabled={!!doc}
                  />{" "}
                  Single Code
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="is-single"
                    checked={!isSingleGenerator}
                    onChange={(e) => setIsSingleGenerator(!e.target.checked)}
                    disabled={!!doc}
                  />{" "}
                  Multiple Codes
                </label>
              </div>
            </div>
          </>
        )}
        {isSingleGenerator && (
          <div className="field">
            <label className="label">Code</label>
            <div className="control">
              <input
                className="input"
                name="code"
                type="text"
                placeholder="Code cannot contain 0, O, I, L, or 1"
                defaultValue={doc ? doc.id || doc.data().code : null}
              />
            </div>
          </div>
        )}
        {!isSingleGenerator && (
          <div className="field">
            <label className="label">Number of Codes</label>
            <input
              type="number"
              name="numberOfCodes"
              className="input"
              defaultValue={1}
              min={1}
              max={10000}
              disabled={!!doc}
            />
          </div>
        )}
        <div className="field">
          <label className="label">Downloads Allowed</label>
          <input
            type="number"
            name="downloadsAllowed"
            className="input"
            defaultValue={doc ? doc.data().downloadsAllowed : 1}
            min={1}
          />
        </div>
        {doc && (
          <div className="field">
            <label className="label">Downloads Used</label>
            <input
              type="number"
              name="downloadsUsed"
              className="input"
              readOnly={true}
              disabled={true}
              defaultValue={doc ? doc.data().downloadsUsed : 0}
            />
          </div>
        )}
      </form>
    </div>
  )
}

export default CodeGeneratorForm
